import restaurant from "./images/select.webp";
import biserica from "./images/biserica.jpg";
import img_card from "./images/12.jpeg";
import imgheadermb from "./images/panda.webp";
import imgheader from "./images/panda.webp";
import imgheadermiini from "./images/panda4.jpg";
import imgheadermiinimb from "./images/panda3.jpg";
import logo from './images/panda-logo.png'



const data = {
    introData: [{
        copilul: "Ștefan & Damian",
        familia: "fam. Mînăscurtă",
        logo: logo,
        tata: "Alexandr",
        mama: "Cristina",
        data: "11 August 2022",
        data_confirmare: "1 august 2022",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "minascurtaalexandr1989@gmail.com", 
       tel: "+373 688 111 45",
       phone: "tel:+37368811145",
       viber: "viber://chat?number=%2B37368811145",
       whatsapp: "https://wa.me/+37368811145",
       messenger: "https://www.messenger.com/t/cristina.minascurtacretu",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Select Banquet Hall",
            data: "11 august 2022, joi, ora 18:00",
            adress: "Bd. Mircea cel Bătrîn 21, Chișinău",
            harta: "https://goo.gl/maps/eMwikp9yypNCjZLR8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10874.277670206235!2d28.8896538!3d47.0486794!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x34f83d2e4acaac48!2sSelect%20Banquet%20Hall!5e0!3m2!1sro!2s!4v1656164848999!5m2!1sro!2s"
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copiii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, sunt pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;